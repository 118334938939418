//import { render } from '@testing-library/react';
import React, { useState,useEffect } from 'react';

function Jin539(){

  const [datasets, setDatasets] = useState([]);

  let imghtml = (star,type='n') => {
    if (type==='n'){
      return <div className='star star-sz'>{star}</div>
    }else if (type==='s'){
      return <div className='star-s star-sz'>{star}</div>
    }
  }

  useEffect( () => {
    fetch( 'https://aps.ezis.idv.tw/Jin539/', {}) 
    .then(res => res.json()) 
    .then(data => {
        setDatasets(data.sort( function(a,b){
          return a.open_date > b.open_date ? -1 : 1;
        }));
    })
    .catch(e => {
        alert('Error:' + e);
    })    

  },[]);




    const weekNames = ['日','一','二','三','四','五','六'];
    return (
      <div>
        <h2>今彩539</h2>
        <div>
        {datasets.map( (item,i) => (
            <div key={i} className='row mt-3'>
            <div className='col-12 col-md-3 mt-3 text-left'>{item.open_date}&nbsp;{weekNames[new Date(item.open_date).getDay()] }</div>
            <div className='col-12 col-md-9'>
              <div className='row mt-1'>
                <div className='col-2'>{imghtml(item.num_1)}</div>
                <div className='col-2'>{imghtml(item.num_2)}</div>
                <div className='col-2'>{imghtml(item.num_3)}</div>
                <div className='col-2'>{imghtml(item.num_4)}</div>
                <div className='col-2'>{imghtml(item.num_5)}</div>
              </div>
            </div>
          </div>
            ))}
        </div>
      </div>
    );
}

export default Jin539;

/*

                <div key={i} className='row'>
                  <div className='col col-8 col-md-3'>{item.open_date}&nbsp;{weekNames[new Date(item.open_date).getDay()] }</div>
                  <div className='col col-12 col-md-6'>{imghtml(item.num_1)}&nbsp;{imghtml(item.num_2)}&nbsp;{imghtml(item.num_3)}&nbsp;{imghtml(item.num_4)}&nbsp;{imghtml(item.num_5)}</div>
                </div>
*/
