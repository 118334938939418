import React from 'react';
import {Routes,Route} from 'react-router-dom';
//import Home from './components/Home';
import Hk6 from './components/Hk6';
import Dalo from './components/Dalo';
import Wali from './components/Wali';
import Jin539 from './components/Jin539';
import Layout from './components/Layout';

function App() {
  return (
    <Layout>
      <Routes>
        <Route exact path='/' element={<Hk6 />} />
        <Route exact path='/Hk6' element={<Hk6 />} />
        <Route exact path='/Dalo' element={<Dalo />} />
        <Route exact path='/Wali' element={<Wali />} />
        <Route exact path='/Jin539' element={<Jin539 />} />
      </Routes>
    </Layout>
  );
}

export default App;
