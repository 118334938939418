import React from 'react';

export default function NavBar(props){
  /*
      const [isOpen,setOpen] = useState(false);
  
      const toggle = () =>{
          setOpen(!isOpen);
        }
  */    
      return (
          <div>
  <nav className="navbar navbar-expand-lg navbar-light bg-light">
    <a className="navbar-brand" href="/">星彩網</a>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarScroll">
      <ul className="navbar-nav mr-auto my-2 my-lg-0 navbar-nav-scroll">
        <li className="nav-item active">
          <a className="nav-link" href="/Hk6">六合彩 </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/Dalo">大樂透</a>
        </li>
        <li className="nav-item active">
          <a className="nav-link" href="/Wali">威力彩 </a>
        </li>
        <li className="nav-item active">
          <a className="nav-link" href="/Jin539">今彩539 </a>
        </li>
      </ul>
    </div>
  </nav>
  </div>
      );
  }
  