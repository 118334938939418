import React from 'react';
import NavBar from './NavBar';

function Layout(props){
  
  //const style1 = {background:'#ffffff'};

  console.log("20221224v1");

  return (
      <div className='app-wrapper'>
          <header><NavBar /></header>
          <div className='container-fluid'>{props.children}</div>
          <footer>
            <div>本網站僅做資料收集，並無做其他用途。</div>
          </footer>
      </div>
  );
}

export default Layout;