//import { render } from '@testing-library/react';
import React, { useState,useEffect } from 'react';

function Hk6(){

  const [datasets, setDatasets] = useState([]);

  let imghtml = (star,type='n') => {
    if (type==='n'){
      return <div className='star star-sz'>{star}</div>
    }else if (type==='s'){
      return <div className='star-s star-sz'>{star}</div>
    }
  }

  useEffect( () => {
    fetch( 'https://aps.ezis.idv.tw/Hk6/', {}) 
    .then(res => res.json()) 
    .then(data => {
        setDatasets(data.sort( function(a,b){
          return a.open_date > b.open_date ? -1 : 1;
        }));
    })
    .catch(e => {
        alert('Error:' + e);
    })    

  },[]);




    const weekNames = ['日','一','二','三','四','五','六'];
    return (
      <div>
        <h2>六合彩</h2>
        <div>
        {datasets.map( (item,i) => (
            <div key={i} className='row mt-2'>
              <div className='col-12 col-md-3 mt-3 text-left'>{item.open_date}&nbsp;{weekNames[new Date(item.open_date).getDay()] }</div>
              <div className='col-9 col-md-7'>
                <div className='row mt-1'>
                  <div className='col-2'>{imghtml(item.num_1)}</div>
                  <div className='col-2'>{imghtml(item.num_2)}</div>
                  <div className='col-2'>{imghtml(item.num_3)}</div>
                  <div className='col-2'>{imghtml(item.num_4)}</div>
                  <div className='col-2'>{imghtml(item.num_5)}</div>
                  <div className='col-2'>{imghtml(item.num_6)}</div>
                </div>
              </div>
              <div className='col'>{imghtml(item.num_s,'s')}</div>
            </div>
            ))}
        </div>
      </div>
    );
}

export default Hk6;


/*

                <div key={i} className='row'>
                  <div className='col-xs-12 col-md-4 ml-2 mt-3 text-left'>{item.open_date}&nbsp;{weekNames[new Date(item.open_date).getDay()] }</div>
                  <div className='col-xs-11 ml-3'>
                    A
                    <div className='row'>
                      <div className='col-xs-2'>{imghtml(item.num_1)}</div>
                      <div className='col-xs-2'>{imghtml(item.num_2)}</div>
                      <div className='col-xs-2'>{imghtml(item.num_3)}</div>
                      <div className='col-xs-2'>{imghtml(item.num_4)}</div>
                      <div className='col-xs-2'>{imghtml(item.num_5)}</div>
                      <div className='col-xs-2'>{imghtml(item.num_6)}</div>
                    </div>
                  </div>
                  <div className='col-xs-1 col-md-1 ml-1'>
                    <div className='col'>{imghtml(item.num_s,'s')}</div>
                  </div>
                </div>
*/

/*
        <table className='star-table'>
          <thead>
            <tr>
              <th>日期</th>
              <th>獎號</th>
              <th >特</th>
            </tr>
          </thead>
          <tbody>
          {datasets.map( (item,i) => (
                <tr key={i}>
                  <td>{item.open_date}&nbsp;{weekNames[new Date(item.open_date).getDay()] }</td>
                  <td>{imghtml(item.num_1)} {imghtml(item.num_2)} {imghtml(item.num_3)} {imghtml(item.num_4)} {imghtml(item.num_5)} {imghtml(item.num_6)}</td>
                  <td>{imghtml(item.num_s,'s')}</td>
                </tr>
            ))}
          </tbody>
        </table>

*/